
import { defineComponent } from "vue";

export default defineComponent({
    name: "Button",
    data() {
        return {
        };
    },
    props: {
        copy: {
            type: String,
            required: true
        },
        icon: {
            type: String,
            required: false
        },
    },
    methods: {
    },
});
