
import { defineComponent } from 'vue';
import Registration from '@/components/Registration.vue';
import ShareCard from '@/components/ShareCard.vue';
import Footer from '@/components/Footer.vue';
import NavBar from '@/components/NavBar.vue';

export default defineComponent({
  name: "Thanks",
  components: { Footer, NavBar, Registration, ShareCard },
  data() {
    return {
      cardData: {},
    };
  },
  created() {
    const data = localStorage.getItem('sharecard');
    if (data) this.cardData = JSON.parse(data);
  },
  methods: {
    redirect() {
      this.$router.push({
        name: 'Home',
        query: { inapp: this.$route.query.inapp == 'true' ? true : false }
      });
    }
  }
});
