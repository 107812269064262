
import { defineComponent } from "vue";

export default defineComponent({
  name: "PlayerGridPlayer",
  props: {
    position: {
      type: Number,
      required: false,
    },
    player: {
      type: Object,
      required: true,
    },
    shirts: {
      type: Object,
      required: true,
    },
    isSub: {
      type: Boolean,
      required: false,
    },
    replaced: {
      type: Boolean,
      required: false,
    },
    size: {
      type: String,
      required: false,
    },
  },
  computed: {
    playerShirt() {
      return this.player.formation_place == 1
        ? this.shirts.keeperJerseyImage
        : this.shirts.jerseyImage;
    },
  },
});
