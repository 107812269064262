
import { defineComponent, nextTick } from "vue"
import Splash from '@/components/Splash.vue';

export default defineComponent({
  components: { Splash },
  data() {
    return {
      splashActive: true,
    };
  },
  created() {
    this.splashCheck()
  },
  async mounted() {
    setTimeout(() => {
      this.detectEnvironment()
    }, 2000)
  },
  methods: {
    splashCheck() {
      //Only if router is home
      setTimeout(() => {
        if (this.$route.name != 'Home') this.splashActive = false
        else return
      }, 50)
      setTimeout(() => {
        this.splashActive = false;
      }, 3500)
    },
    detectEnvironment() {
      if (this.$route.query.inapp != 'true') {
        //If its not in app create script
        let cookie = document.createElement("script");

        //Set required cookie attributes
        cookie.setAttribute("src", `https://consent.cookiebot.com/uc.js`);
        cookie.setAttribute("id", `Cookiebot`);
        cookie.setAttribute("data-cbid", `7ce68bab-49d7-4ad1-a7db-46cfd79f6f63`);
        cookie.setAttribute("data-blockingmode", `auto`);
        cookie.setAttribute("type", `text/javascript`);

        // Add cookie to head
        document.head.appendChild(cookie);
      }

      let adobeTracking = document.createElement("script");
      //Set required adobeTracking attributes
      const adobeDtm = 'https://assets.adobedtm.com/7da23f9e2089/26fec3429152/launch-a8a76f526c25.min.js'
      adobeTracking.setAttribute("src", adobeDtm);
      adobeTracking.setAttribute("id", `adobetracking`);
      adobeTracking.setAttribute("type", `text/javascript`);
      // Add adobeTracking to head
      document.head.appendChild(adobeTracking);
      console.log(process.env.VUE_APP_APP_ENV);
    }
  }
})
