export namespace Helper {

  export function getBaseUrl(): string {
    return `${window.location.protocol}//${window.location.host}`;
  }

  export function shuffleArray(data: any[]): any[] {
    for (let i = data.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [data[i], data[j]] = [data[j], data[i]];
    }
    return data;
  }

  export function getAPIDate(): string {
    const date = new Date();
    return date.toISOString();
  }

  export function isDevelopment(): boolean {
    return process.env.VUE_APP_APP_ENV === "development";
  }

  export function isIOS(): boolean {
    const regex = /iPhone|iPad|iPod/i;
    return regex.test(navigator.userAgent);
  }

  export async function gtmPush(label: string) {
    // Wait till loaded if not adobe track
    if (!document.getElementById('adobetracking'))
      await new Promise(resolve => setTimeout(resolve, 3000));

    (window as any).__dataLayer = (window as any).__dataLayer || {};
    let _dataLayer = {
      "region": "emea",
      "country": "nl",
      "language": "nl",
      "anonymized": "true",
      "article_url": "",
      "platform": "web",
      "section": "",
      "section1": "",
      "ex_cmp": "",
      "cmp": "",
      "brand_name": "motm",
      "page_name": "emea:uk:motm:" + label, //  page name
      "currency": 'EUR',
      "event": 'pageview',
      "content_type": '',
    };

    if (navigator.cookieEnabled && document.getElementById('Cookiebot').classList.contains('enabled')) {
      (window as any)._satellite.track('pageview', _dataLayer);
      console.log("tracking: ", label);
    }
  }
}