import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-53caa2b2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "player-grid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlayerGridPlayer = _resolveComponent("PlayerGridPlayer")!
  const _component_SplideSlide = _resolveComponent("SplideSlide")!
  const _component_Splide = _resolveComponent("Splide")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["player-grid", `formation-${_ctx.formation.replaceAll('-', '') || '433'}`])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.basePlayers, (player, index) => {
        return (_openBlock(), _createBlock(_component_PlayerGridPlayer, {
          size: "small",
          player: player,
          shirts: _ctx.shirts,
          replaced: _ctx.subsOff.includes(player.athlete_id) ? true : false,
          onClick: ($event: any) => (_ctx.$emit('votePlayer', player))
        }, null, 8, ["player", "shirts", "replaced", "onClick"]))
      }), 256))
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["subs", {empty : _ctx.substitutePlayers < 1}])
    }, [
      (_ctx.substitutePlayers.length >= 1)
        ? (_openBlock(), _createBlock(_component_Splide, {
            key: 0,
            "data-splide": "{\"pagination\": false,\"perPage\":4}",
            class: "splide"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.substitutePlayers, (player, index) => {
                return (_openBlock(), _createBlock(_component_SplideSlide, {
                  class: _normalizeClass(["slide", `slides-${_ctx.substitutePlayers.length} slide-${index + 1}`])
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_PlayerGridPlayer, {
                      size: "small",
                      player: player,
                      isSub: true,
                      shirts: _ctx.shirts,
                      replaced: _ctx.subsOff.includes(player.athlete_id) ? true : false,
                      onClick: ($event: any) => (_ctx.$emit('votePlayer', player))
                    }, null, 8, ["player", "shirts", "replaced", "onClick"])
                  ]),
                  _: 2
                }, 1032, ["class"]))
              }), 256))
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}