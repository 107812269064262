
import { defineComponent } from "vue";

export default defineComponent({
  name: "Filter",
  props: {
    name: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    type: {
      type: String,
      required: false
    },
  },
  data() {
    return {
      navigationActive: false
    };
  },
  methods: {
    switchOption(option: any) {
      const activeValue = option.target.value
      this.$emit('filterChange', activeValue)
    }
  },
});
