
import { defineComponent } from 'vue';
import Filter from '@/components/Filter.vue';
import Match from '@/components/CardMatch.vue';
import Footer from '@/components/Footer.vue';
import NavBar from '@/components/NavBar.vue';
import { Helper } from "@/utils/_Helper";

export default defineComponent({
  name: "Help",
  components: { Filter, Match, Footer, NavBar },
  data() {
    return {
      title: '',
      body: ''
    };
  },
  created() {
    this.getPageData();
    Helper.gtmPush('help');
  },
  methods: {
    async getPageData() {
      try {
        // Get and set weeks data
        const helpData = await this.axios.get(`${process.env.VUE_APP_API_URL}/main/getPage?page=help`);
        this.title = helpData.data.data.title;
        this.body = helpData.data.data.copy;
      } catch (error) {
        console.log(error);
      }
    }
  }
});
