
import { defineComponent } from 'vue';
import Footer from '@/components/Footer.vue';
import NavBar from '@/components/NavBar.vue';
import Registration from '@/components/Registration.vue';
import { Helper } from "@/utils/_Helper";

export default defineComponent({
  name: "Thanks",
  created(){
    localStorage.removeItem('votes')
    Helper.gtmPush('thanks');
  },
  components: { Footer, NavBar, Registration }
});
