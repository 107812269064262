
import { defineComponent } from 'vue';
import Filter from '@/components/Filter.vue';
import Match from '@/components/CardMatch.vue';
import PopupMatch from '@/components/popups/MatchDetails.vue';
import Footer from '@/components/Footer.vue';
import NavBar from '@/components/NavBar.vue';
import { Helper } from "@/utils/_Helper";

export default defineComponent({
  name: "Overview",
  components: { Filter, Match, PopupMatch, Footer, NavBar },
  data() {
    return {
      matches: null,
      weeks: null,
      winner: '',
      overviewVideo: '',
      currentSeason: '',
      seasons: [],
      matchData: null
    };
  },
  created() {
    this.getCurrentSeason()
    Helper.gtmPush('overview');
  },
  methods: {
    async getCurrentSeason(){
      try {
        // Get and set weeks data
        const getSeasons = await this.axios.get(`${process.env.VUE_APP_API_URL}/motm/getSeasons`);
        // Set current season to most reason season
        this.currentSeason = getSeasons.data.data[getSeasons.data.data.length - 1];
        this.seasons = getSeasons.data.data.reverse();
        
        // Get remaining page data
        this.getPageData();

      } catch (error) {
        console.log(error);
      }
    },
    async getPageData() {
      try {
        // Get and set weeks data
        const weeksData = await this.axios.get(`${process.env.VUE_APP_API_URL}/motm/getMatchWeeks?season=${this.currentSeason}`);
        this.weeks = weeksData.data.data;

        // Get and set matches data
        const matchesData = await this.axios.get(`${process.env.VUE_APP_API_URL}/motm/getResults?season=${this.currentSeason}&match_week=${this.weeks[0]}`);

        this.matches = matchesData.data.data.matches;
        this.winner = matchesData.data.data.winner;
        this.overviewVideo = matchesData.data.data.overviewVideo;
      } catch (error) {
        console.log(error);
      }
    },
    async getMatchData(match: any) {
      try {
        // Get match details
        const matchData = await this.axios.get(`${process.env.VUE_APP_API_URL}/motm/getDetails?match_id=${match.match_id}`);
        this.matchData = matchData.data.data;

      } catch (error) {
        console.log(error);
      }
    },
    async filterWeek(week: string) {
      // Get and set matches data
      const matchesData = await this.axios.get(`${process.env.VUE_APP_API_URL}/motm/getResults?season=${this.currentSeason}&match_week=${week}`);
      this.matches = matchesData.data.data.matches;
      this.winner = matchesData.data.data.winner;
      this.overviewVideo = matchesData.data.data.overviewVideo;
    },
    async filterSeason(value: string) {
      this.currentSeason = value
      // Correcting of value format
      const weeksData = await this.axios.get(`${process.env.VUE_APP_API_URL}/motm/getMatchWeeks?season=${this.currentSeason}`);
      this.weeks = weeksData.data.data;
      // Get and set matches data
      const matchesData = await this.axios.get(`${process.env.VUE_APP_API_URL}/motm/getResults?season=${this.currentSeason}`);
      this.matches = matchesData.data.data.matches;
      this.winner = matchesData.data.data.winner;
    },
    closePopup() {
      //remove match data
      this.matchData = null;
    }
  }
});
