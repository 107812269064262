
import { defineComponent } from "vue";

export default defineComponent({
    name: "MatchDetails",
    data() {
        return {
            isVotes: false
        };
    },
    props: {
        matchData: {
            type: Object,
            required: true
        },
    },
    created() {
    },
    methods: {
    },
    computed: {
        motm() {
            return this.matchData.motm
        },
        stats() {
            return this.matchData.motm.stats
        },
        matchStats() {
            const position = this.matchData.motm.position;
            switch (position) {
                case 'Keeper':
                    return [
                        {
                            name: "Reddingen",
                            value: `${this.stats.saves ? this.stats.saves : 0}`
                        },
                        {
                            name: "Doelpunten tegen",
                            value: `${this.stats.goalsConceded ? this.stats.goalsConceded : '0'}`
                        },
                        {
                            name: "Doeltrappen",
                            value: `${this.stats.goalsConceded ? this.stats.goalsConceded : '0'}`
                        },
                        {
                            name: "Doeltrappen",
                            value: `${this.stats.goalKicks}`
                        }
                    ]
                case 'Centrale verdediger':
                case 'Verdediger':
                    return [
                        {
                            name: "Goals / Assists",
                            value: `${this.stats.goals ? this.stats.goals : 0} /  ${this.stats.goalAssist ? this.stats.goalAssist : 0}`
                        },
                        {
                            name: "Intercepties",
                            value: `${this.stats.interception ? this.stats.interception : 0}`
                        },
                        {
                            name: "Gewonnen duels",
                            value: `${this.stats.totalTackle && this.stats.wonTackle ? Math.floor((this.stats.wonTackle / this.stats.totalTackle) * 100) + '%' : '-'}`
                        },
                        {
                            name: "Succesvolle passes",
                            value: `${this.stats.totalPass && this.stats.accuratePass ? Math.floor((this.stats.accuratePass / this.stats.totalPass) * 100) + '%' : '-'}`
                        }
                    ]
                case 'Vleugelverdediger':
                    return [
                        {
                            name: "Goals / Assists",
                            value: `${this.stats.goals ? this.stats.goals : 0} /  ${this.stats.goalAssist ? this.stats.goalAssist : 0}`
                        },
                        {
                            name: "Succesvolle dribbels",
                            value: `${this.stats.wonContest ? this.stats.wonContest : 0}`
                        },
                        {
                            name: "Gewonnen duels",
                            value: `${this.stats.totalTackle && this.stats.wonTackle ? Math.floor((this.stats.wonTackle / this.stats.totalTackle) * 100) + '%' : '-'}`
                        },
                        {
                            name: "Succesvolle passes",
                            value: `${this.stats.totalPass && this.stats.accuratePass ? Math.floor((this.stats.accuratePass / this.stats.totalPass) * 100) + '%' : '-'}`
                        }
                    ]
                case 'Middenvelder':
                    return [
                        {
                            name: "Goals / Assists",
                            value: `${this.stats.goals ? this.stats.goals : 0} /  ${this.stats.goalAssist ? this.stats.goalAssist : 0}`
                        },
                        {
                            name: "Heroveringen Bal",
                            value: `${this.stats.ballRecovery ? this.stats.ballRecovery : '0'}`
                        },
                        {
                            name: "Succesvolle passes",
                            value: `${this.stats.totalPass && this.stats.accuratePass ? Math.floor((this.stats.accuratePass / this.stats.totalPass) * 100) + '%' : '-'}`
                        },
                        {
                            name: "Kansen gecreëerd",
                            value: `${this.stats.totalScoringAtt ? this.stats.totalScoringAtt : 0}`
                        }
                    ]
                case 'Aanvaller':
                    return [
                        {
                            name: "Goals / Assists",
                            value: `${this.stats.goals ? this.stats.goals : 0} /  ${this.stats.goalAssist ? this.stats.goalAssist : 0}`
                        },
                        {
                            name: "Succesvolle dribbels",
                            value: `${this.stats.wonContest ? this.stats.wonContest : 0}`
                        },
                        {
                            name: "Succesvolle passes",
                            value: `${this.stats.totalPass && this.stats.accuratePass ? Math.floor((this.stats.accuratePass / this.stats.totalPass) * 100) + '%' : '-'}`
                        },
                        {
                            name: "Kansen gecreëerd",
                            value: `${this.stats.totalScoringAtt ? this.stats.totalScoringAtt : 0}`
                        }
                    ]
                case 'Spits':
                    return [
                        {
                            name: "Goals / Assists",
                            value: `${this.stats.goals ? this.stats.goals : 0} /  ${this.stats.goalAssist ? this.stats.goalAssist : 0}`
                        },
                        {
                            name: "Gewonnen duels",
                            value: `${this.stats.totalTackle && this.stats.wonTackle ? Math.floor((this.stats.wonTackle / this.stats.totalTackle) * 100) + '%' : '-'}`
                        },
                        {
                            name: "Kansen gecreëerd",
                            value: `${this.stats.totalScoringAtt ? this.stats.totalScoringAtt : 0}`
                        },
                        {
                            name: "Schoten op doel",
                            value: `${this.stats.ontargetScoringAtt ? this.stats.ontargetScoringAtt : 0}`
                        }
                    ]
            }
        },
        top() {
            return this.matchData['top']
        },
        clubs() {
            return this.matchData['motm']['club_information'][0]
        },
    }
});
