import { PageName } from '@/utils/_Constants';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Home from '../views/Home.vue';
import Overview from '../views/Overview.vue';
import Players from '../views/Players.vue';
import Thanksv1 from '../views/Thanks.vue';
import Thanks from '../views/Thanksv2.vue';
import Help from '../views/Help.vue';
import Vote from '../views/Vote.vue';
import Register from '../views/Register.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/vote/:matchid',
    name: 'Vote',
    component: Vote
  },
  {
    path: '/overview',
    name: 'Overview',
    component: Overview
  },
  {
    path: '/players',
    name: 'Players',
    component: Players
  },
  {
    path: '/help',
    name: 'Help',
    component: Help
  },
  {
    path: '/thanksv1',
    name: 'Thanksv1',
    component: Thanksv1
  },
  {
    path: '/thanks',
    name: 'Thanks',
    component: Thanks
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router;
