
import { defineComponent } from "vue";
import Button from '@/components/base/Button.vue';

export default defineComponent({
  name: "NavBar",
  components: { Button },
  props: {
    page: {
      type: String,
      required: true,
    },
    activeTab: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      navigationActive: false,
      currentStep: 0,
      totalSteps: 3,
      activeSteps: false,
      inProgress: false,
      thanksNavigation: true,
      inapp: false
    };
  },
  created() {
    if (this.$route.query.inapp == 'true') {
      //check if its in app, if inapp = hide footer
      this.inapp = true;
    }
    switch (this.page) {
      case 'home':
      case 'matches':
        this.currentStep = 1;
        this.activeSteps = true;
        break;
      case 'vote':
        this.inProgress = true;
        this.currentStep = 2;
        this.activeSteps = true;
        break;
      case 'thanks':
        this.inProgress = true;
        this.currentStep = 3;
        this.activeSteps = true;
        this.thanksNavigation = true;
        break;
      default:
        break;
    }
  },
  methods: {
    toggleNavigation() {
      this.navigationActive = !this.navigationActive;
    },
    redirect(route: string) {
      switch (route) {
        case 'matches':
          this.$router.push({ name: 'Home' })
          this.activeSteps = true;
          break;
        case 'overview':
          this.$router.push({ name: 'Overview' })
          this.activeSteps = false;
          break;
        case 'players':
          this.$router.push({ name: 'Players' })
          this.activeSteps = false;
          break;
        case 'help':
          this.$router.push({ name: 'Help' })
          this.activeSteps = false;
          break;
        default:
          break;
      }
      this.navigationActive = false;
    }
  },
});
