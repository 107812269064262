import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-74c60d9e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "button" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("img", {
      class: "icon",
      src: require(`../../assets/images/icons/${_ctx.icon}`),
      alt: "icon"
    }, null, 8, _hoisted_2),
    _createElementVNode("span", {
      class: "copy",
      innerHTML: _ctx.copy
    }, null, 8, _hoisted_3)
  ]))
}