import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-35d6bba0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "player box" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 1,
  class: "name holder"
}
const _hoisted_4 = { class: "name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["player", _ctx.size])
    }, [
      (_ctx.shirt)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: "shirt",
            src: _ctx.shirt,
            alt: "shirt"
          }, null, 8, _hoisted_2))
        : _createCommentVNode("", true),
      _createElementVNode("span", {
        class: "number",
        style: _normalizeStyle({ 'color': _ctx.textColor })
      }, _toDisplayString(_ctx.number), 5),
      (_ctx.name)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.name), 1)
          ]))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}