
import { defineComponent } from 'vue';
import PlayersGrid from '@/components/PlayersGrid.vue';
import Footer from '@/components/Footer.vue';
import PopupPlayer from '@/components/popups/PlayerDetails.vue';
import NavBar from '@/components/NavBar.vue';
import { Helper } from "@/utils/_Helper";
export default defineComponent({
  name: "Vote",
  components: { PlayersGrid, Footer, PopupPlayer, NavBar },
  data() {
    return {
      matchData: null,
      isHome: true,
      popupActive: false,
      playerData: null,
      matchId: '',
      inapp: false,
      votedMatches: [],
    };
  },
  created() {
    this.getMatchData()
    Helper.gtmPush('match');
    this.inapp = this.$route.query.inapp == 'true' ? true : false;
    this.checkVotedMatches();
  },
  methods: {
    async getMatchData() {
      try {
        this.matchId = this.$route.params.matchid;
        const response = await this.axios.get(`${process.env.VUE_APP_API_URL}/motm/getMatch?match_id=${this.matchId}`);
        this.matchData = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    switchTeam(isHome: boolean) {
      if (!this.isHome == isHome)
        this.isHome = !this.isHome
    },
    async votePopup(playerData: any) {
      try {
        const response = await this.axios.get(`${process.env.VUE_APP_API_URL}/motm/getPlayer?match_id=${this.matchId}&athlete_id=${playerData.athlete_id}`);
        this.playerData = response.data.data;
        this.popupActive = true;
        Helper.gtmPush('vote');
      } catch (error) {
        console.log(error);
      }
    },
    closePopup() {
      this.popupActive = false;
    },
    checkVotedMatches() {
      // Checking of previous votes
      this.matchId = this.$route.params.matchid;
      let votedMatches = JSON.parse(localStorage.getItem('votes'));
      if (votedMatches) {
        for (const [key, value] of Object.entries(votedMatches)) {
          this.votedMatches.push(value)
        }
        if (votedMatches.includes(this.matchId))
          this.$router.push({ name: 'Home' })
      }
    }
  },
  computed: {
    activeTeam() {
      if (this.isHome)
        return this.matchData['contestant_home']
      else
        return this.matchData['contestant_away']
    },
    activeFormation() {
      return this.activeTeam['formation_used'].split('').join('-');
    }
  }
});
