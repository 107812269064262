import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d5a2db10"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`item position-${_ctx.player.formation_place} ${_ctx.size} ${_ctx.isSub ? 'sub' : 'field'}  ${_ctx.replaced ? 'replace' : ''}`),
    style: _normalizeStyle(`background-image: url(${_ctx.playerShirt})`)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["status", { substitute: _ctx.player.substituted_out || _ctx.player.substituted_in }])
    }, null, 2),
    _createElementVNode("div", {
      class: "number",
      style: _normalizeStyle(`color: ${_ctx.player.formation_place == 1 ? _ctx.shirts.keeperJerseyNumberColor : _ctx.shirts.jerseyNumberColor}`)
    }, _toDisplayString(_ctx.player.shirt_number), 5),
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.player.match_name), 1)
  ], 6))
}