
import { defineComponent } from "vue";
import Shirt from '@/components/PlayerShirt.vue';

export default defineComponent({
  name: "CardMatch",
  components: { Shirt },
  props: {
    match: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
    };
  },
  methods: {
  },
  computed: {
    matchTimestamp() {
      const day = this.match['match_date'].slice(8, 10) as string;
      const months = ['jan', 'feb', 'mar', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'];
      const month = months[this.match['match_date'].slice(5, 7) as number - 1];
      const time = this.match['match_time'].slice(0, 5) as string;
      return `${day} ${month} ${time}`
    },
  }
});
