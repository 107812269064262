import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-738f9de4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card match" }
const _hoisted_2 = { class: "timestamp" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "score" }
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 0,
  class: "motm visual"
}
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Shirt = _resolveComponent("Shirt")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, [
      _createElementVNode("p", null, _toDisplayString(_ctx.matchTimestamp), 1)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["center", { 'align-left': !_ctx.match['motm'] }])
    }, [
      _createElementVNode("img", {
        class: "home icon",
        src: _ctx.match['home_logo'],
        alt: ""
      }, null, 8, _hoisted_3),
      _createElementVNode("p", _hoisted_4, _toDisplayString(`${_ctx.match['home_goals']} - ${_ctx.match['away_goals']}`), 1),
      _createElementVNode("img", {
        class: "away icon",
        src: _ctx.match['away_logo'],
        alt: ""
      }, null, 8, _hoisted_5)
    ], 2),
    (_ctx.match['motm'] && _ctx.match['motm']['image_location'])
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("img", {
            class: "visual",
            src: _ctx.match['motm']['image_location'],
            alt: "player"
          }, null, 8, _hoisted_7),
          _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.match['motm']['match_name']), 1)
        ]))
      : (_ctx.match['motm'])
        ? (_openBlock(), _createBlock(_component_Shirt, {
            key: 1,
            class: "motm",
            name: _ctx.match['motm']['match_name'],
            shirt: _ctx.match['motm']['image'],
            size: "match",
            number: _ctx.match['motm']['number'],
            textColor: _ctx.match['motm']['font_color']
          }, null, 8, ["name", "shirt", "number", "textColor"]))
        : _createCommentVNode("", true)
  ]))
}