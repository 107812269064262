
import { defineComponent } from "vue";

export default defineComponent({
  name: "PlayerShirt",
  props: {
    name: {
      type: String,
      required: false
    },
    number: {
      type: String,
      required: true
    },
    shirt: {
      type: String,
      required: true
    },
    textColor: {
      type: String,
      required: false
    },
    size: {
      type: String,
      required: false
    },
  },
  data() {
    return {
    };
  },
  methods: {
  },
  computed: {
  }
});
