
import { defineComponent } from "vue";

export default defineComponent({
  name: "Footer",
  data() {
    return {
      inapp: false
    };
  },
  created() {
    if (this.$route.query.inapp == 'true') {
      //check if its in app, if inapp = hide footer
      this.inapp = true;
    }
  },
  methods: {
    redirect(type: string) {
      switch (type) {
        case 'terms':
          window.open('https://disneytermsofuse.com/dutch-netherlands/', 'blank');
          break;
        case 'privacy_general':
          window.open('https://privacy.thewaltdisneycompany.com/nl/privacybeleid/', 'blank');
          break;
        case 'privacy_eu_uk':
          window.open('https://privacy.thewaltdisneycompany.com/nl/privacybeleid/privacybeleid-2/', 'blank');
          break;
        case 'personal_ads':
          window.open('https://preferences-mgr.truste.com/?pid=disney01&aid=espnemea01&type=espn', 'blank');
          break;
        case 'cookies':
          window.open('https://privacy.thewaltdisneycompany.com/nl/privacybeleid/cookiesbeleid/', 'blank');
          break;
        default:
          break;
      }
    }
  },
});
