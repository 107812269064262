
import { defineComponent } from "vue";
import PlayerShirt from '@/components/PlayerShirt.vue';

export default defineComponent({
  name: "PlayerDetails",
  components: { PlayerShirt },
  data() {
    return {
    };
  },
  props: {
    playerData: {
      type: Object,
      required: true
    },
    matchData: {
      type: Object,
      required: true
    },
    shirt: {
      type: Object,
      required: true
    },
    matchId: {
      type: String,
      required: true
    }
  },
  methods: {
    async voteMotm() {

      try {

        // Set sharecard and registration page data
        const shareCardData = {
          name: this.playerData['match_name'],
          position: this.playerData['match_data']['position'],
          game: `${this.matchData['contestant_home']['name']} - ${this.matchData['contestant_away']['name']}`,
          shirtNumber: this.playerData['shirt_number'],
          shirtImage: this.playerData['shirt'] || this.playerData['shirt_k'],
          textColor: this.playerData['shirt_font_color'] || this.playerData['shirt_k_font_color'],
          playerImage: this.playerData['image_location'],
          clubImage: this.playerData['logo']
        }
        const voteData = { athleteId: this.playerData['athlete_id'], matchId: this.matchId }
        localStorage.setItem('sharecard', JSON.stringify(shareCardData))
        localStorage.setItem('votedata', JSON.stringify(voteData))

        // retrieved voted matches
        if (localStorage.getItem('votes')) {
          let previousVotedMatches = JSON.parse(localStorage.getItem('votes'));
          let votedMatches = [this.matchId]
          if (previousVotedMatches) {
            for (const [key, value] of Object.entries(previousVotedMatches)) {
              votedMatches.push(value)
            }
          }
          // Adding current match id to voted matches
          localStorage.setItem('votes', JSON.stringify(votedMatches))
        } else {
          localStorage.setItem('votes', JSON.stringify([this.matchId]))
        }
        this.$router.push({
          name: 'Register',
          query: { inapp: this.$route.query.inapp == 'true' ? true : false }
        })
        // // TEMPORARY VOTING FROM HERE
        // this.axios.post(`${process.env.VUE_APP_API_URL}/motm/registerAndVote`, {
        //   athlete_id: this.playerData['athlete_id'],
        //   match_id: this.matchData['match_id'],
        //   skip_registration: '1'
        // });
        // if (this.$route.query.inapp != 'true') {
        //   this.$router.push({
        //     name: 'Thanks',
        //     query: { inapp: this.$route.query.inapp == 'true' ? true : false }
        //   })
        // } else {
        //   this.$router.push({
        //     name: 'Thanks',
        //     query: { inapp: this.$route.query.inapp == 'true' ? true : false }
        //   })
        // }

      } catch (error) {
        console.log(error);
      }
    }
  },
  computed: {
    results() {
      const match = this.matchData
      const home = match['contestant_home']
      const away = match['contestant_away']
      return `${home['name']} ${match['home_goals']} - ${away['name']} ${match['away_goals']}`
    },
    stats() {
      return this.playerData['match_data']['stats']
    },
    motmStats() {
      const position = this.playerData['match_data']['position'] as String || 'verdediger';
      switch (position.toLocaleLowerCase()) {
        case 'keeper':
          return [
            {
              name: "Reddingen",
              value: `${this.stats.saves ? this.stats.saves : 0}`
            },
            {
              name: "Doelpunten tegen",
              value: `${this.stats.goalsConceded ? this.stats.goalsConceded : '0'}`
            },
            {
              name: "Reddings percentage",
              value: `${this.stats.saves && this.stats.ontargetScoringAtt && this.stats.clearanceOffLine ? Math.floor(this.stats.saves / (this.stats.ontargetScoringAtt / this.stats.clearanceOffLine) * 100) + '%' : '-'}`
            },
            {
              name: "Penalty’s gered",
              value: `${this.stats.penaltySave && this.stats.penaltyFaced ? this.stats.penaltySave + ' / ' + this.stats.penaltyFaced : 0 + ' / ' + 0}`
            }
          ]
        case 'centrale verdediger':
        case 'verdediger':
          return [
            {
              name: "Goals / Assists",
              value: `${this.stats.goals ? this.stats.goals : 0} /  ${this.stats.goalAssist ? this.stats.goalAssist : 0}`
            },
            {
              name: "Intercepties",
              value: `${this.stats.interception ? this.stats.interception : 0}`
            },
            {
              name: "Gewonnen duels",
              value: `${this.stats.totalTackle && this.stats.wonTackle ? Math.floor((this.stats.wonTackle / this.stats.totalTackle) * 100) + '%' : '-'}`
            },
            {
              name: "Succesvolle passes",
              value: `${this.stats.totalPass && this.stats.accuratePass ? Math.floor((this.stats.accuratePass / this.stats.totalPass) * 100) + '%' : '-'}`
            }
          ]
        case 'vleugelverdediger':
          return [
            {
              name: "Goals / Assists",
              value: `${this.stats.goals ? this.stats.goals : 0} /  ${this.stats.goalAssist ? this.stats.goalAssist : 0}`
            },
            {
              name: "Succesvolle dribbels",
              value: `${this.stats.wonContest ? this.stats.wonContest : 0}`
            },
            {
              name: "Gewonnen duels",
              value: `${this.stats.totalTackle && this.stats.wonTackle ? Math.floor((this.stats.wonTackle / this.stats.totalTackle) * 100) + '%' : '-'}`
            },
            {
              name: "Succesvolle passes",
              value: `${this.stats.totalPass && this.stats.accuratePass ? Math.floor((this.stats.accuratePass / this.stats.totalPass) * 100) + '%' : '-'}`
            }
          ]
        case 'middenvelder':
          return [
            {
              name: "Goals / Assists",
              value: `${this.stats.goals ? this.stats.goals : 0} /  ${this.stats.goalAssist ? this.stats.goalAssist : 0}`
            },
            {
              name: "Heroveringen Bal",
              value: `${this.stats.ballRecovery ? this.stats.ballRecovery : '0'}`
            },
            {
              name: "Succesvolle passes",
              value: `${this.stats.totalPass && this.stats.accuratePass ? Math.floor((this.stats.accuratePass / this.stats.totalPass) * 100) + '%' : '-'}`
            },
            {
              name: "Kansen gecreëerd",
              value: `${this.stats.totalScoringAtt ? this.stats.totalScoringAtt : 0}`
            }
          ]
        case 'aanvaller':
          return [
            {
              name: "Goals / Assists",
              value: `${this.stats.goals ? this.stats.goals : 0} /  ${this.stats.goalAssist ? this.stats.goalAssist : 0}`
            },
            {
              name: "Succesvolle dribbels",
              value: `${this.stats.wonContest ? this.stats.wonContest : 0}`
            },
            {
              name: "Succesvolle passes",
              value: `${this.stats.totalPass && this.stats.accuratePass ? Math.floor((this.stats.accuratePass / this.stats.totalPass) * 100) + '%' : '-'}`
            },
            {
              name: "Kansen gecreëerd",
              value: `${this.stats.totalScoringAtt ? this.stats.totalScoringAtt : 0}`
            }
          ]
        case 'spits':
          return [
            {
              name: "Goals / Assists",
              value: `${this.stats.goals ? this.stats.goals : 0} /  ${this.stats.goalAssist ? this.stats.goalAssist : 0}`
            },
            {
              name: "Gewonnen duels",
              value: `${this.stats.totalTackle && this.stats.wonTackle ? Math.floor((this.stats.wonTackle / this.stats.totalTackle) * 100) + '%' : '-'}`
            },
            {
              name: "Kansen gecreëerd",
              value: `${this.stats.totalScoringAtt ? this.stats.totalScoringAtt : 0}`
            },
            {
              name: "Schoten op doel",
              value: `${this.stats.ontargetScoringAtt ? this.stats.ontargetScoringAtt : 0}`
            }
          ]
      }
    }
  }
});
