import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ba296b08"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page help" }
const _hoisted_2 = { class: "full-width" }
const _hoisted_3 = {
  key: 0,
  class: "textbox"
}
const _hoisted_4 = { class: "subtitle" }
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NavBar, {
      page: "help",
      activeTab: "help"
    }),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.body)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.title), 1),
            _createElementVNode("p", { innerHTML: _ctx.body }, null, 8, _hoisted_5)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_Footer)
  ]))
}