
import { defineComponent } from "vue";

export default defineComponent({
  name: "Registration",
  data() {
    return {
      date: {
        day: null,
        month: null,
        year: null,
      },
      form: {
        athlete_id: '',
        match_id: '',
        skip_registration: '0',
        first_name: '',
        last_name: '',
        email: '',
        opt_in_espn: false,
        opt_in_ecv: false,
        opt_in_mail: false,
        favorite_club_id: '',
        birth_date: null,
      },
      errors: [],
      nextStepClicked: false
    };
  },
  mounted() {
    const voteData = JSON.parse(localStorage.getItem('votedata'));
    this.form.athlete_id = voteData['athleteId']
    this.form.match_id = voteData['matchId']
  },
  methods: {
    checkDay(event: any) {
      const value = event.target.value;
      if(this.nextStepClicked)this.validateInputs()
      if (String(value).length <= 2) {
        this.date.day = value;
      }
      this.$forceUpdate();
    },
    checkMonth(event: any) {
      const value = event.target.value;
      if(this.nextStepClicked)this.validateInputs()
      if (String(value).length <= 2) {
        this.date.month = value;
      }
      this.$forceUpdate();
    },
    checkYear(event: any) {
      const value = event.target.value;
      if(this.nextStepClicked)this.validateInputs()
      if (String(value).length <= 4) {
        this.date.year = value;
      }
      this.$forceUpdate();
    },

    async nextStep(skip: boolean) {
      this.nextStepClicked = true
      try {
        // register vote
        if (!skip) {
          const isValid = await this.validateInputs()
          if (!isValid) return
          await this.axios.post(`${process.env.VUE_APP_API_URL}/motm/registerAndVote`, this.form);
        } else {
          await this.axios.post(`${process.env.VUE_APP_API_URL}/motm/registerAndVote`, {
            athlete_id: this.form.athlete_id,
            match_id: this.form.match_id,
            skip_registration: '1'
          });
        }

        // redirecting to match voting screen
        this.$router.push({
          name: 'Thanks',
          query: { inapp: this.$route.query.inapp == 'true' ? true : false }
        });
      } catch (err) {
        console.log("Something went wrong");
      }
    },
    validateInputs() {
      if (this.date.day && this.date.month && this.date.year)
        this.form.birth_date = `${this.date.day}-${this.date.month}-${this.date.year}`

      this.errors = []
      if (!this.form.first_name) this.errors.push('first_name')
      if (!this.form.last_name) this.errors.push('last_name')
      if (!this.form.email) this.errors.push('email')
      if (!this.form.favorite_club_id) this.errors.push('favorite_club_id')
      if (!this.form.birth_date || !this.isAdult(this.form.birth_date)) this.errors.push('birth_date')

      return this.errors.length >= 1 ? false : true
    },
    isAdult(birthdate: string) {
      const [day, month, year] = birthdate.split('-').map(Number);
      const birthDate = new Date(year, month - 1, day);
      const currentDate = new Date();
      const age = currentDate.getFullYear() - birthDate.getFullYear() - ((currentDate.getMonth() < birthDate.getMonth() || (currentDate.getMonth() === birthDate.getMonth() && currentDate.getDate() < birthDate.getDate())) ? 1 : 0);
      return age >= 18;
    }
  },
});
