
import { defineComponent } from "vue";
import Shirt from '@/components/PlayerShirt.vue';

export default defineComponent({
  name: "ShareCard",
  components: { Shirt },
  props: {
    cardData: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
    };
  },
  methods: {
  },
  computed: {
  }
});
