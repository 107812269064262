
import { defineComponent } from "vue";
import PlayerGridPlayer from "./PlayerGridPlayer.vue";
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import "@splidejs/splide/dist/css/themes/splide-default.min.css";

export default defineComponent({
  name: "PlayerGrid",
  components: {
    PlayerGridPlayer,
    Splide,
    SplideSlide
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    subs: {
      type: Object,
      required: true,
    },
    formation: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      subsOn: [],
      subsOff: []
    };
  },
  created() {
    this.updateSubs()
  },
  methods: {
    updateSubs() {
      Object.values(this.subs).forEach(val => {
        this.subsOn.push(val['athlete_on_id'])
        this.subsOff.push(val['athlete_off_id'])
      });
    }
  },
  computed: {
    basePlayers() {
      return this.data.players.filter((player: any) => player.formation_place != '');
    },
    substitutePlayers() {
      return this.data.players.filter((player: any) => this.subsOn.includes(player.athlete_id));
    },
    shirts() {
      let shirts = {
        jerseyImage: this.data.shirts.shirt,
        jerseyNumberColor: this.data.shirts.shirt_font_color,
        keeperJerseyImage: this.data.shirts.shirt_k,
        keeperJerseyNumberColor: this.data.shirts.shirt_k_font_color,
      };
      return shirts;
    },
  },
});
