
import { defineComponent } from "vue";
import { PageName } from "@/utils/_Constants";
import { Vue3Lottie } from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'
import logoLottie from '@/assets/data/lotties/lottie-logo.json'

export default defineComponent({
  name: "Splash",
  data() {
    return {
      logoLottie,
    };
  },
  components: { Vue3Lottie },
  created(){
  }
});
