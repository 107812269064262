
import { defineComponent } from 'vue';
import Footer from '@/components/Footer.vue';
import NavBar from '@/components/NavBar.vue';
import { Helper } from "@/utils/_Helper";

export default defineComponent({
  name: "Home",
  components: { Footer, NavBar },
  data() {
    return {
      infoCopy: 'Vandaag zijn er geen wedstrijden en kan er niet gestemd worden voor KPN Man of the Match!',
      matches: null,
      gamesToday: false,
      votedMatches: []
    };
  },
  created() {
    this.getPageData();
    Helper.gtmPush('games');
  },
  methods: {
    async getPageData() {
      try {
        // Get and matches overview data
        const matchData = await this.axios.get(`${process.env.VUE_APP_API_URL}/motm/getMatches`);
        this.matches = matchData.data.data;
        this.checkMatchesToday()
        this.checkVotedMatches()
      } catch (error) {
        console.log(error);
      }
    },
    checkVotedMatches() {
      // Checking of previous votes
      if (!localStorage.getItem('votes')) return

      let votedMatches = JSON.parse(localStorage.getItem('votes'));
      if (votedMatches) {
        for (const [key, value] of Object.entries(votedMatches)) {
          this.votedMatches.push(value)
        }
      }
    },
    checkMatchesToday() {
      // check for matches today / hide no matches copy
      const today = new Date().toISOString().slice(0, 10);
      const currentGames = this.matches.filter(game => game.match_date == today);
      if (currentGames.length > 0)
        this.gamesToday = true;
    },
    redirectMatch(match: string, status: string) {
      // Only allow redirect for active matches
      if (status.toLowerCase() != 'playing' || this.votedMatches.includes(match))
        return

      // redirecting to match voting screen
      this.$router.push({
        name: 'Vote',
        params: { matchid: match },
        query: { inapp: this.$route.query.inapp == 'true' ? true : false }
      });
    },
    matchTimestamp(index: any) {
      const match = this.matches[index];
      const match_status = match['match_status'];
      if (match_status == 'Playing') return match['match_play_time'] + "'"
      else {
        const months = ['jan', 'feb', 'mar', 'apr', 'mei', 'jun', 'jul', 'aug', 'sept', 'okt', 'nov', 'dec'];
        const day = parseInt(match['match_date'].slice(8, 10));
        const month = months[parseInt(match['match_date'].slice(5, 7)) - 1];
        const time = match['match_time'].slice(0, 5);
        if (new Date(match['match_date']).getDate() == new Date().getDate())
          return `${time}`
        else
          return `${day} ${month}. ${time}`
      }
    },
    matchStatus(index: any) {
      const match = this.matches[index];
      const match_status = match['match_status'];
      const match_play_time = match['match_play_time'];
      const match_date = match['match_date'];
      const voted = this.votedMatches.includes(match['match_id']) ? true : false

      const playDay = new Date(match_date).toLocaleDateString("nl-NL", { weekday: 'long' });

      const matchStatusCopy = voted ? 'Gestemd' :
        match_status == 'Fixture' ? playDay :
          match_status == 'Playing' && match_play_time >= 1 ? 'Stem nu' :
            match_status == 'Playing' && match_play_time < 1 ? 'Aankomend' :
              match_status == 'Played' ? 'Gesloten' :
                match_status == 'Cancelled' ? 'Geannuleerd' :
                  match_status == 'Postponed' ? 'Afgelast' :
                    match_status == 'Suspended' ? 'Gestaakt' :
                      match_status == 'Awarded' ? 'Toegekend' :
                        match_status == 'None' ? 'Aankomend' : 'TBD';

      return matchStatusCopy.toLocaleLowerCase()
    }
  },
  computed: {
  }
});
