import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-07b7a322"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "filter" }
const _hoisted_2 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("h5", null, _toDisplayString(_ctx.name), 1),
    _createElementVNode("select", {
      onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.switchOption($event)))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
        return (_openBlock(), _createElementBlock("option", {
          key: index,
          value: option
        }, _toDisplayString(_ctx.type? `${_ctx.type} ${option}` : `${option}`), 9, _hoisted_2))
      }), 128))
    ], 32)
  ]))
}