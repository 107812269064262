
import { defineComponent } from 'vue';
import Filter from '@/components/Filter.vue';
import Shirt from '@/components/PlayerShirt.vue';
import Footer from '@/components/Footer.vue';
import NavBar from '@/components/NavBar.vue';
import { Helper } from "@/utils/_Helper";

export default defineComponent({
  name: "Players",
  components: { Filter, Shirt, Footer, NavBar },
  data() {
    return {
      seasonData: null,
      seasons: [],
      currentSeason: ''
    };
  },
  created() {
    this.getCurrentSeason()
    Helper.gtmPush('players');
  },
  methods: {
    async getCurrentSeason(){
      try {
        // Get and set weeks data
        const getSeasons = await this.axios.get(`${process.env.VUE_APP_API_URL}/motm/getSeasons`);
        // Set current season to most reason season
        this.currentSeason = getSeasons.data.data[getSeasons.data.data.length - 1];
        this.seasons = getSeasons.data.data.reverse();
        
        // Get remaining page data
        this.getPageData();

      } catch (error) {
        console.log(error);
      }
    },
    async getPageData() {
      try {
        // Get and set matches data
        const seasonData = await this.axios.get(`${process.env.VUE_APP_API_URL}/motm/getPlayerStats?season=${this.currentSeason}`);
        this.setData(seasonData.data.data)
      } catch (error) {
        console.log(error);
      }
    },
    async filterSeason(season: string) {
      try {
        //remove current data
        this.seasonData = null;
        // Get and set matches data
        const seasonData = await this.axios.get(`${process.env.VUE_APP_API_URL}/motm/getPlayerStats?season=${season.replace(/\s/g, '')}`);
        this.setData(seasonData.data.data)
      } catch (error) {
        console.log(error);
      }
    },
    async setData(data: any) {
      // setting of data, only add if it has required info
      if(!data) return
      const filtered = data.filter(player => player['name'] && player['match_name']);
      this.seasonData = filtered;
    }
  }
});
